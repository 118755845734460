import React, { useState, useCallback } from 'react';
import { useLocales } from 'use-locales';
import { Form, Button } from '../../components';
import Balance from './balance';
import Budgets from './budgets';
import Vouchers from './vouchers';

const Main = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const onYearSubmit = useCallback((data) => {
    setYear(data.year);
  }, []);

  const messages = useLocales('main');

  return (
    <>
      <Form className="form" onSubmit={onYearSubmit}>
        <div className="field has-addons">
          <div className="control">
            <input
              className="input"
              name="year"
              defaultValue={year}
              type="number"
            />
          </div>
          <div className="control">
            <Button className="button is-primary" type="submit">
              {messages.update}
            </Button>
          </div>
        </div>
      </Form>

      <Vouchers year={year} />

      <Balance year={year} />

      <h2 className="title">{messages.budget.title}</h2>
      <Budgets year={year} />
    </>
  );
};

export default Main;
