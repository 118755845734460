import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './login';
import Main from './main';
import classes from './screens.module.scss';

// from https://reacttraining.com/react-router/web/example/route-config
const routes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/',
    component: Main,
  },
];

const Router = ({ children }) => {
  return (
    <>
      {children}

      <div className={classes.body}>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </div>
    </>
  );
};

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

export default Router;
