import React, { useEffect, useContext } from 'react';
import { Form, Button } from '../../components';
import { UserContext } from '../../providers/user';

const Login = () => {
  const { login, logout } = useContext(UserContext);

  useEffect(() => {
    logout();
  }, []);

  return (
    <>
      <Form className="form" onSubmit={login}>
        <div className="field">
          <label className="label">Email</label>
          <p className="control">
            <input
              className="input"
              type="email"
              name="email"
              placeholder="Email"
            />
          </p>
        </div>
        <div className="field">
          <p className="control">
            <label className="label">Password</label>
            <input
              className="input"
              type="password"
              name="password"
              placeholder="Password"
            />
          </p>
        </div>
        <div className="field">
          <p className="control">
            <label className="label">Project</label>
            <input
              className="input"
              type="text"
              name="project"
              placeholder="Project"
            />
          </p>
        </div>
        <div className="field">
          <p className="control">
            <Button className="button is-primary" type="submit">
              Login
            </Button>
          </p>
        </div>
      </Form>
    </>
  );
};

export default Login;
