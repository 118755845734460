import React from 'react';
import Router from './screens';
import './app.scss';

const App = () => {
  return (
    <Router>
      <header>
        Dashboard
      </header>
    </Router>
  );
};

export default App;
