import React, { createContext } from 'react';

export const ErrorsContext = createContext();

class ErrorsProvider extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.setErrors = this.setErrors.bind(this);
    this.state = {
      errors: null,
    };
  }

  setErrors(errors) {
    this.setState((state) => ({ ...state, errors }));
  }

  componentDidCatch(error, info) {
    // prepare message
    let componentName;
    let url;
    try {
      componentName = info.componentStack.split(' in ')[1].split(' ')[0];
    } catch {}
    try {
      url = window.location.href.replace(window.location.origin, '');
    } catch {}
    let message = error.message;
    if (componentName || url) {
      message = `${message}<br /><br /><ul>`;
      if (componentName)
        message += `<li>Component: <strong>${componentName}.jsx</strong></li>`;
      if (url) message += `<li>Path: <strong>${url}</strong>`;
      message += '</ul>';
    }

    // go back to / so error is not triggered in a loop
    // TODO: maybe we should do an error screen dedicated to this
    window.history.pushState(undefined, undefined, '/');

    // print error
    this.setState((state) => ({
      ...state,
      errors: [{ title: 'Client error', message }],
    }));
  }

  render() {
    const { children } = this.props;
    const { errors } = this.state;

    return (
      <ErrorsContext.Provider value={{ errors, setErrors: this.setErrors }}>
        {children}
      </ErrorsContext.Provider>
    );
  }
}

export default ErrorsProvider;
