import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { LocalesProvider } from 'use-locales';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import ErrorsPovider from './providers/errors';
import GraphQLProvider from './providers/graphClientProvider';
import UserProvider from './providers/user';

if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });
}

ReactDOM.render(
  <ErrorsPovider>
    <LocalesProvider hashKey={process.env.REACT_APP_GIT_SHA || Date.now()}>
      <BrowserRouter>
        <GraphQLProvider>
          <UserProvider>
            <App />
          </UserProvider>
        </GraphQLProvider>
      </BrowserRouter>
    </LocalesProvider>
  </ErrorsPovider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
