import { useState, useCallback } from 'react';
import { useGraphQL } from '../../../components';

export const useBudgets = () => {
  const [budgets, setBudgets] = useState([]);
  const graphql = useGraphQL();

  const getBudgets = useCallback(
    async ({ year }) => {
      const { errors, ...data } = await graphql(`
        query GetBudgets {
          budgets (filter: { year: ${year} }) {
            id
            value
            description
          }
        }
      `);

      if (errors) return;

      const { budgets } = data;
      setBudgets(budgets);
    },
    [graphql],
  );

  const addBudget = useCallback((budget) => {
    setBudgets((old) => [budget, ...old]);
  }, []);

  const removeBudget = useCallback((id) => {
    setBudgets((old) => old.filter((budget) => budget.id !== id));
  });

  return [budgets, getBudgets, addBudget, removeBudget];
};
