import React, { useState, useCallback, Children } from 'react';
import { fromEvent } from 'from-form-submit';
import cn from 'classnames';
import Button from './button';

const Form = ({ children, onSubmit, ...props }) => {
  const [loading, setLoading] = useState();

  const mapButtons = useCallback(
    (child) => {
      if (!child || !child.type) {
        return child;
      }
      if (child.type === Button) {
        return React.cloneElement(child, { loading });
      }
      if (child.type === 'button') {
        return React.cloneElement(child, {
          disabled: child.props.disabled || loading,
          className: cn(child.props.className, {
            'is-loading': child.props.type === 'submit' && loading,
          }),
        });
      }
      if (!child.props || !child.props.children) {
        return child;
      }
      return React.cloneElement(child, {
        children: Children.map(child.props.children, mapButtons),
      });
    },
    [loading],
  );

  const innerOnSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      e.persist();
      setLoading(true);
      await onSubmit(fromEvent(e), e);
      setLoading(false);
    },
    [onSubmit],
  );

  return (
    <form {...props} onSubmit={innerOnSubmit}>
      {Children.map(children, mapButtons)}
    </form>
  );
};

export default Form;
