import React from 'react';
import { useLocales } from 'use-locales';
import classes from './vouchers.module.scss';


const previousMonth = new Date(Date.now());
previousMonth.setMonth(previousMonth.getMonth() - 1);

const Vouchers = ({ year }) => {
  // locales
  const messages = useLocales('main.vouchers');

  return (
    <div className={classes.container}>
      <a 
        className="button"
        href={`${(process.env.NODE_ENV === 'development' &&
          process.env.REACT_APP_DOWNLOAD_VOUCHERS_URL) ||
          ''}/api/download/vouchers?year=${year}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {messages.download}
      </a>
      <a
        className="button"
        href={`${(process.env.NODE_ENV === 'development' &&
          process.env.REACT_APP_DOWNLOAD_VOUCHERS_URL) ||
          ''}/api/download/vouchers?year=${previousMonth.getYear() +
          1900}&month=${previousMonth.getMonth()}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {messages.downloadPreviousMonth}
      </a>
    </div>
  );
};

export default Vouchers;
