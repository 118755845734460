import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import classes from './modal.module.scss';

const INTERACTIVE_ELEMENTS = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  .split(',')
  .map((elt) => `${elt.trim()}:not([disabled])`)
  .join(',');

const Modal = ({ className, active, title, onClose, actions, children }) => {
  const modalsRef = useRef(document.getElementById('modals'));
  const cardRef = useRef();

  useEffect(() => {
    if (!active) return;

    cardRef.current.focus();
  }, [active]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!active) return;

    // from: https://github.com/delphinemillet/devenez-accessible/blob/master/src/components/modal/modal.jsx
    // and: https://gomakethings.com/how-to-get-the-first-and-last-focusable-elements-in-the-dom/
    const focusables = Array.from(
      cardRef.current.querySelectorAll(INTERACTIVE_ELEMENTS),
    ).filter((elt) => getComputedStyle(elt).display !== 'none');
    const [firstFocusable] = focusables;
    const lastFocusable = focusables[focusables.length - 1];

    if (!cardRef.current.contains(window.document.activeElement)) {
      firstFocusable.focus();
    }

    const onKeyDown = (event) => {
      const { code, ctrlKey, altKey, shiftKey, metaKey } = event;

      if (code === 'Escape') {
        if (onClose) onClose();
        return;
      }

      if (code === 'Tab') {
        if (altKey || ctrlKey || metaKey) return;

        if (shiftKey && window.document.activeElement === firstFocusable) {
          event.preventDefault();
          lastFocusable.focus();
          return;
        }

        if (window.document.activeElement === lastFocusable) {
          event.preventDefault();
          firstFocusable.focus();
          return;
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [active, onClose]);

  return createPortal(
    <div className={cn(className, 'modal', { 'is-active': active })}>
      <div className="modal-background" onClick={onClose} />
      <div className="modal-card" ref={cardRef}>
        <header className="modal-card-head">
          <div className={cn(classes.title, 'modal-card-title')}>{title}</div>
          {onClose && (
            <button className="delete" aria-label="close" onClick={onClose} />
          )}
        </header>
        <section className="modal-card-body">{children}</section>
        <footer className={cn('modal-card-foot is-centered', classes.center)}>
          {actions}
        </footer>
      </div>
    </div>,
    modalsRef.current,
  );
};

export default Modal;
