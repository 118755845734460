import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useLocales } from 'use-locales';
import { useBalance } from './balance.operations';
import classes from './balance.module.scss';

const Bar = ({ className, children, max, value, wrapped = false, message }) => {
  const percent = value / max;

  return (
    <div className={cn(classes.bar, className, { [classes.wrapped]: wrapped })}>
      <div
        className={classes.coloredBar}
        style={{ width: `${percent * 100}%` }}
      >
        <div className={cn(classes.barColorBg, className)} />
        {children}
      </div>
      {message && <div className={classes.message}>{message}</div>}
    </div>
  );
};

const Balance = ({ year }) => {
  // data
  const [balance, getBalance] = useBalance();
  const [maxBarValue, setMaxBarValue] = useState(0);

  // locales
  const messages = useLocales('main.balance', balance);
  const barsMessages = useLocales('main.balance.bars', balance.bars);

  // year modification
  useEffect(() => {
    getBalance({ year });
  }, [year, getBalance]);

  // balance modification
  useEffect(() => {
    const values = [
      balance.bars.expenses + balance.bars.budgets,
      Math.max(balance.bars.sells, balance.bars.invoices),
      balance.bars.revenues + balance.bars.plannedRevenues,
    ];
    setMaxBarValue(values.sort((a, b) => b - a)[0]);
  }, [balance]);

  return (
    <div className={classes.balanceContainer}>
      <div className={cn('container', classes.title)}>
        <div className={classes.balance}>{messages.title}</div>
      </div>

      <div className={classes.bars}>
        <Bar
          className={classes.totalSells}
          max={maxBarValue}
          value={Math.max(balance.bars.sells, balance.bars.invoices)}
          message={`${barsMessages.invoices} & ${barsMessages.sells}`}
        >
          <Bar
            className={classes.invoices}
            max={Math.max(balance.bars.sells, balance.bars.invoices)}
            value={balance.bars.invoices}
            wrapped
          />
        </Bar>

        <Bar
          className={classes.totalRevenues}
          max={maxBarValue}
          value={balance.bars.revenues + balance.bars.plannedRevenues}
          message={`${barsMessages.revenues} & ${barsMessages.plannedRevenues}`}
        >
          <Bar
            className={classes.revenues}
            max={balance.bars.revenues + balance.bars.plannedRevenues}
            value={balance.bars.revenues}
            wrapped
          />
        </Bar>
        <Bar
          className={classes.totalExpenses}
          max={maxBarValue}
          value={balance.bars.expenses + balance.bars.budgets}
          message={`${barsMessages.expenses} & ${barsMessages.budgets}`}
        >
          <Bar
            className={classes.expenses}
            max={balance.bars.expenses + balance.bars.budgets}
            value={balance.bars.expenses}
            wrapped
          />
        </Bar>
      </div>
    </div>
  );
};

export default Balance;
