import { useContext } from 'react';
import { ErrorsContext } from '../providers/errors';

const useMessagePrinter = () => {
  const { setErrors } = useContext(ErrorsContext);

  return { setErrors };
};

export default useMessagePrinter;
