import React, { useState, createContext, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGraphQL } from '../components';

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const graphql = useGraphQL();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  const logout = useCallback(() => {
    window.localStorage.removeItem('auth');
    graphql('mutation Disconnect { disconnect }');
    history.push('/login');
  }, []);

  const login = useCallback(async ({ email, password, project }) => {
    setLoading(true);

    const { login, errors } = await graphql(`
      query Login {
        login: token(
          email: "${email.trim()}",
          password: "${password}",
          project: "${project.trim().toLowerCase()}"
        ) {
          token
          email
          name
          firstName
          roles
          projects {
            code
            name
            parameters {
              primaryColor
              secondaryColor
            }
          }
        }
      }
    `);

    setLoading(false);

    if (errors) return;

    const {
      roles,
      projects,
      token, // we don't store token in local storage for security reasons
      ...info
    } = login;
    const projectInfos = projects.find(({ code }) => code === project);
    const auth = JSON.stringify({ roles, projects, ...info });

    window.localStorage.setItem('auth', auth);
    window.localStorage.setItem('project', JSON.stringify(projectInfos));

    const redirectTo = window.localStorage.getItem('redirect-to');
    setUser(auth);
    history.push(redirectTo || '/');
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      const user = window.localStorage.getItem('auth');
      if (user) setUser(JSON.parse(user));
    } catch (ex) {
      console.error(ex);
    }
    setLoading(false);
  }, []);

  return (
    <UserContext.Provider
      value={{
        loading,
        authenticated: !!user,
        user,
        login,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
