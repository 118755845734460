import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import createGraphQLClient from '@fabienjuif/graph-client';
import { useMessagePrinter } from '../components';

if (typeof window === 'undefined') {
  throw new Error('This provider is not mean to work in SSR');
}
export const GraphClientContext = createContext();

const GraphClientProviderWithAuth = ({ children, loadingElm = null }) => {
  const history = useHistory();
  const [graphqlClient, setGraphqlClient] = useState();
  const { setErrors } = useMessagePrinter();

  const logout = useCallback(() => {
    history.push('/login');
  }, []);

  useEffect(() => {
    console.info('Creating new GraphQL client...');
    const createGqlClient = async () => {
      const rawClient = createGraphQLClient({
        url: '/graphql',
        fetch: window.fetch,
      });

      const wrappedClient = (...args) => {
        if (process.env.NODE_ENV === 'development') {
          try {
            const type = args[0]
              .trim()
              .split(' ')[0]
              .trim();
            const name = args[0]
              .split(type)[1]
              .split('{')[0]
              .trim();
            console.debug(`[GraphQL] {${type}} - ${name}`);
          } catch {
            console.debug('[GraphQL] unknown...');
          }
        }
        return rawClient(...args).catch((errors) => {
          if (Array.isArray(errors)) {
            const mappedErrors = errors.map(({ message, ...raw }) => {
              const error = new Error(`API (graphql): ${message || 'Unknown'}`);
              Object.assign(error, raw);
              console.error(error.message, raw);
              return error;
            });
            mappedErrors.map((error) => Sentry.captureException(error));
          } else {
            Sentry.captureException(errors);
          }

          if (
            Array.isArray(errors) &&
            errors.find((error) =>
              ['UNAUTHORIZED', 'TOKEN_INVALID'].includes(error.extensions.code)
              || ['UNAUTHORIZED', 'TOKEN_INVALID'].includes(error.extensions.exception.code),
            )
          ) {
            logout();
          } else {
            try {
              setErrors([
                { title: 'Request error', message: errors[0].message },
              ]);
            } catch {
              setErrors([{ title: 'Unknown error', message: errors }]);
            }
          }

          return { errors };
        });
      };

      setGraphqlClient(() => wrappedClient);
    };

    createGqlClient();
  }, [logout, setErrors]);

  if (!graphqlClient) return loadingElm;

  return (
    <GraphClientContext.Provider
      value={{
        graphqlClient,
      }}
    >
      {children}
    </GraphClientContext.Provider>
  );
};

export default GraphClientProviderWithAuth;
