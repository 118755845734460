import { useState, useCallback } from 'react';
import { useGraphQL } from '../../../components';

const queryExpenses = (year, limit, lastId) => `
  expenses (filter: { year: ${year} }, limit: ${limit} ${
  lastId ? `, lastId: "${lastId}"` : ''
}) {
    id
    prices {
      totalWithoutTaxes
    }
  }`;

export const useBalance = () => {
  const limit = 100;
  const graphql = useGraphQL();
  const [balance, setBalance] = useState({ bars: {} });

  const getBalance = useCallback(
    async ({ year }) => {
      const { errors, ...data } = await graphql(`
      query GetBalanceForYear {
        ${queryExpenses(year, limit)}

        invoices (filter: { year: ${year} }) {
          prices {
            totalWithoutTaxes
          }
        }
  
        humanResources(filter: { year: ${year} }) {
          revenues {
            value
            dates {
              paid
            }
          }

          charges {
            planned
            values {
              value
            }
          }
        }

        sellsObjectives(filter: { year: ${year} }) {
          total
        }

        budgets(filter: { year: ${year} }) {
          value
        }
      }
    `);

      if (errors) return;

      const {
        invoices,
        humanResources,
        expenses,
        sellsObjectives,
        budgets,
      } = data;

      let haveMoreExpenses = expenses.length === limit;
      while (haveMoreExpenses) {
        const { err, expenses: moreExpenses } = await graphql(`
        query GetMoreExpenses {
          ${queryExpenses(year, limit, expenses[expenses.length - 1].id)}
        }`);
        if (err) return;

        expenses.push(...moreExpenses);
        haveMoreExpenses = moreExpenses.length === limit;
      }

      const totalInvoices = invoices.reduce(
        (acc, invoice) => acc + invoice.prices.totalWithoutTaxes,
        0,
      );
      const totalExpenses = expenses.reduce(
        (acc, expense) => acc + expense.prices.totalWithoutTaxes,
        0,
      );
      const totalPlannedRevenues = humanResources.reduce(
        (total, { revenues }) =>
          total +
          revenues
            .filter(({ dates }) => !dates.paid)
            .reduce((acc, { value }) => acc + value, 0),
        0,
      );
      const totalRevenues = humanResources.reduce(
        (total, { revenues }) =>
          total +
          revenues
            .filter(({ dates }) => dates.paid)
            .reduce((acc, { value }) => acc + value, 0),
        0,
      );
      const totalCharges = humanResources.reduce(
        (total, { charges }) =>
          total +
          charges.reduce(
            (acc, { values }) =>
              acc + values.reduce((acc, { value }) => acc + value, 0),
            0,
          ),
        0,
      );
      const totalPlannedCharges = humanResources.reduce(
        (total, { charges }) =>
          total + charges.reduce((acc, { planned }) => acc + planned, 0),
        0,
      );
      const totalSells = sellsObjectives.reduce(
        (acc, sell) => acc + sell.total,
        0,
      );
      const totalBudgets = budgets.reduce(
        (acc, budget) => acc + budget.value,
        0,
      );

      const balance = {
        bars: {
          expenses: totalExpenses,
          budgets: totalBudgets,
          plannedRevenues:
            totalPlannedRevenues + totalPlannedCharges - totalCharges,
          revenues: totalRevenues + totalCharges,
          invoices: totalInvoices,
          sells: totalSells,
        },
        balance:
          Math.max(totalSells, totalInvoices) -
          totalExpenses -
          totalBudgets -
          totalRevenues -
          totalPlannedRevenues -
          totalPlannedCharges,
      };
      setBalance(balance);
    },
    [graphql],
  );

  return [balance, getBalance];
};
