import React, { useEffect, useCallback, useState } from 'react';
import { useLocales } from 'use-locales';
import { Form, Button, useGraphQL } from '../../../components';
import { useBudgets } from './budgets.operations';
import classes from './budget.module.scss';

const Budget = ({ onRemove, ...budget }) => {
  const graphql = useGraphQL();
  const [{ id, value, description }, setBudget] = useState(budget);

  const onBudgetReplace = useCallback(async (newBudget) => {
    const { errors, ...data } = await graphql(
      `
        mutation ReplaceBudget($input: InputReplaceBudget!) {
          replaceBudget(input: $input) {
            id
            description
            value
          }
        }
      `,
      { input: { ...newBudget, id } },
    );

    if (errors) return;

    setBudget(data.replaceBudget);
  }, []);

  const onBudgetRemove = useCallback(async () => {
    const { errors } = await graphql(
      `
        mutation RemoveBudgetById($input: String!) {
          removeBudget(input: $input)
        }
      `,
      { input: id },
    );

    if (errors) return;

    onRemove(id);
  }, [graphql, onRemove]);

  // locales
  const messages = useLocales('main.budget');

  return (
    <Form className={'form'} onSubmit={onBudgetReplace}>
      <div className="field has-addons">
        <div className="control">
          <input
            className="input"
            name="description"
            type="text"
            defaultValue={description}
          />
        </div>
        <div className="control">
          <input
            className="input"
            name="value"
            type="number"
            defaultValue={value}
          />
        </div>
        <div className="control">
          <Button className="button is-primary" type="submit">
            {messages.update}
          </Button>
          <Button
            className="button is-secondary"
            type="button"
            onClick={onBudgetRemove}
          >
            {messages.remove}
          </Button>
        </div>
      </div>
    </Form>
  );
};

const Budgets = ({ year }) => {
  const [budgets, getBudgets, addBudget, removeBudget] = useBudgets(year);
  const graphql = useGraphQL();

  useEffect(() => {
    getBudgets({ year });
  }, [year, getBudgets]);

  const onBudgetSubmit = useCallback(
    async (newBudget, event) => {
      const { errors, ...data } = await graphql(
        `
          mutation AddBudget($input: InputBudget!) {
            addBudget(input: $input) {
              id
            }
          }
        `,
        { input: newBudget },
      );

      if (errors) return;

      addBudget({ ...newBudget, id: data.addBudget.id });

      // reset form
      if (event.target) event.target.reset();
    },
    [addBudget, graphql],
  );

  // locales
  const messages = useLocales('main.budget');

  return (
    <div className={classes.table}>
      {budgets.map((budget) => (
        <Budget key={budget.id} {...budget} onRemove={removeBudget} />
      ))}

      <Form className="form" onSubmit={onBudgetSubmit}>
        <div className="field has-addons">
          <div className="control">
            <input
              className="input"
              name="description"
              type="text"
              placeholder={messages.description}
            />
          </div>
          <div className="control">
            <input
              className="input"
              name="value"
              type="number"
              placeholder={messages.value}
            />
          </div>
          <div className="control">
            <Button className="button is-primary" type="submit">
              {messages.add}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Budgets;
